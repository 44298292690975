
export function itemFromFunctionItem(pObject:any, items:any|null = null){
    switch(pObject.name){
        case "FULLTEXT_SEARCH":
            return fromFullText(pObject);
        case "exists_clause":
        case "NOT exists_clause":
            return fromExists(pObject, items);
        default:
            return null;
    }
}


function fromFullText(pObject:any){
    const vValue = pObject.items.find((x:any)=>x.type=="literal");

    return {
        column:pObject.items[2].name,
        value:convertFromFullText(vValue.value),
        operator:"full_text"
    }
}

function fromExists(pObject:any, items:any|null){
    let vCol:string = "";
    let vOperator = "exists_clause";
    Object.keys(items).forEach((key:any)=>{
        const item = items[key];
        if(item.exist && item.exist.isFunctionItem(pObject)){
           vCol = key;
           return;
        }
    })

    if(pObject.items[2] && pObject.items[2].operator == "notequals" && !pObject.items[2].value){
        vOperator = "isnotblank";
    }else if(!pObject.items[2] && pObject.name == "NOT exists_clause"){
        vOperator = "isblank";
    }

    if(pObject.items[2] && pObject.items[2].operator){
        vOperator = pObject.items[2].operator;
    }

    
    return {
        column:vCol,
        operator:vOperator,
        valueType:pObject.items[2]?pObject.items[2].valueType:null,
        value: pObject.items[2] && pObject.items[2].value?pObject.items[2].value:null,
    }
}

function convertFromFullText(pValue:string){
    return pValue.replaceAll(" AND ", " ").replaceAll('"','').replaceAll('*','');
}